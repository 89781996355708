import { useEffect } from 'react'
import { useState } from 'react'

function HomeComponent() {
  const [steps, setStep] = useState(1)

  const body = document.getElementsByClassName('body')

  //   const section11TabHeading = document.getElementsByClassName(
  //     'section11TabHeading'
  //   )
  //   const section11TabDescription1 = document.getElementsByClassName(
  //     'section11TabDescription1'
  //   )

  function setValue(event, data) {
    setStep(data)
    // for (let i = 0; i < section11TabHeading.length; i++)
    //   section11TabHeading[i].classList.remove('active')
    // for (let i = 0; i < section11TabDescription1.length; i++)
    //   section11TabDescription1[i].classList.add('hidden')
    // event.classList.add('active')
    // document
    //   .getElementsByClassName('section11div' + data)[0]
    //   .classList.remove('hidden')
  }

  // window.addEventListener('load', () => {})

  function handleChange2(value) {
    if (value === true) {
      body[0].classList.add('darkMode')
      window.localStorage.setItem('theme', 'light')
    } else {
      body[0].classList.remove('darkMode')
      window.localStorage.setItem('theme', 'dark')
    }
  }

  useEffect(() => {
    window.AOS.init()
    new window.Swiper('.mySwiper', {
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    new window.Swiper('.myBannerSwiper', {
      loop: true,
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
      },
    })

    // document.addEventListener('DOMContentLoaded', function () {})

    const theme = localStorage.getItem('theme')
    const themeChecked = document.getElementById('themeChecked')
    if (theme === 'light') {
      body[0].classList.add('darkMode')
      themeChecked.checked = true
    }

    const expandableHeadings =
      document.getElementsByClassName('expandableHeading')
    for (let i = 0; i < expandableHeadings.length; i++) {
      expandableHeadings[i].addEventListener('click', (event) => {
        const currentHeading = event.currentTarget
        currentHeading.classList.toggle('active')
      })
    }
  }, [])

  return (
    <>
      <div className="body">
        <div className="navbar">
          <div className="leftNavbar">
            <p>
              ABS <span>Enterprises</span>
            </p>
          </div>
          <div className="rightNavbar">
            <div className="navMenu">
              <div className="nameContainer">
                <a href="#home" alt="" style={{ textDecoration: 'unset' }}>
                  <div className="toogle">Home</div>
                </a>
              </div>
              <div className="nameContainer">
                <a href="#about" alt="" style={{ textDecoration: 'unset' }}>
                  <div className="toogle">About</div>
                </a>
              </div>
              <div className="nameContainer">
                <div className="toogle">Case</div>
              </div>
              <div className="nameContainer">
                <div className="toogle">Other</div>
              </div>
              <div className="nameContainer">
                <a href="#contact" alt="" style={{ textDecoration: 'unset' }}>
                  <div className="toogle">Contacts</div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <section id="home">
          <div className="layout">
            <div className="swiper myBannerSwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="section15SwiperContainer">
                    <img
                      data-aos="fade-up"
                      className="section15Image1"
                      src="/Images/hero1.jpg"
                      alt=""
                    />
                    <img
                      data-aos="fade-up"
                      className="section15Image2"
                      src="/Images/hero2.jpg"
                      alt=""
                    />
                    <div className="section15BlurContainer"></div>
                    <div className="section15heading1">
                      <h1 data-aos="fade-right">Solutions For</h1>
                      <h1 data-aos="fade-right">IT Business</h1>
                      <p data-aos="fade-right">
                        Code that Connects and Inspires
                        <br />
                        <span>IT Solutions</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>

          <div className="checkbox-wrapper-64">
            <label className="switch">
              <input
                id="themeChecked"
                type="checkbox"
                onChange={(e) => {
                  handleChange2(e.target.checked)
                }}
              />
              <span className="slider"></span>
            </label>
          </div>
        </section>

        <section style={{ padding: '120px 0' }} id="about">
          <div className="section2MainContainer">
            <div className="section2Description">
              <div className="section2DescriptionHeading">
                <p>At ABS Enterprises, we believe that </p>
                <p>
                  <span>effective creative solutions </span>
                  begin with a deep understanding of the challenge.
                </p>
                <br />
                <br />
                <p>
                  By taking the time to thoroughly comprehend the problem, we
                  can develop innovative and tailored solutions that meet our
                  clients' needs.
                </p>
                <br />
              </div>
              <div className="exapndables" data-aos="fade-left">
                <div className="expandableHeading">
                  <div className="expandableHeadingContainer">
                    <p>UX Design</p>
                    <div className="plusExpandIcon">
                      <div className="line1"></div>
                      <div className="line2"></div>
                    </div>
                  </div>
                  <div className="section2expandableHeadingContainer">
                    <p>
                      As a startup, prioritizing UX design is crucial for
                      success. A well-designed product boosts user retention,
                      conversions, and sets you apart from competitors. Keep
                      your design simple, understand your audience, and test
                      with tools like Figma and UserTesting. Invest in UX design
                      to build a product that resonates with users and drives
                      business success.
                    </p>
                  </div>
                </div>

                <div className="expandableHeading">
                  <div className="expandableHeadingContainer">
                    <p>Research</p>
                    <div className="plusExpandIcon">
                      <div className="line1"></div>
                      <div className="line2"></div>
                    </div>
                  </div>
                  <div className="section2expandableHeadingContainer">
                    <p>
                      We conduct feasibility studies for new products,
                      evaluating their technical and financial viability.
                      Additionally, we assess customer demand and potential
                      risks to inform strategic decisions. 😊🚀
                    </p>
                  </div>
                </div>

                <div className="expandableHeading">
                  <div className="expandableHeadingContainer">
                    <p>Website Development</p>
                    <div className="plusExpandIcon">
                      <div className="line1"></div>
                      <div className="line2"></div>
                    </div>
                  </div>
                  <div className="section2expandableHeadingContainer">
                    <p>
                      At <span>ABS Enterprises</span>, we specialize in
                      delivering top-notch website development services at
                      prices that won't break the bank. Our solutions are not
                      only affordable but also reliable and scalable, tailored
                      to meet the specific needs of your project. With us, you
                      can be confident that your website will stand out in the
                      digital landscape, backed by cutting-edge technology and
                      exceptional performance.
                    </p>
                  </div>
                </div>

                <div className="expandableHeading">
                  <div className="expandableHeadingContainer">
                    <p>Branding</p>
                    <div className="plusExpandIcon">
                      <div className="line1"></div>
                      <div className="line2"></div>
                    </div>
                  </div>
                  <div className="section2expandableHeadingContainer">
                    <p>
                      The development of reliable and scalable software
                      solutions for any OS, browser and device. We bring
                      together deep industry expertise and the latest IT
                      advancements to deliver custom solutions and products that
                      perfectly fit the needs and behavior of their users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: '#0f1013', paddingTop: '20px' }}>
          <div className="section3Layout">
            <div className="section3line">
              Join just like the best companies out there
            </div>
          </div>
          <div className="section3Logo">
            <div className="section3LogoContainer">
              <div className="logoWide">
                <img src="/Images/logo1.svg" alt="" />
                <img src="/Images/logo2.svg" alt="" />
                <img src="/Images/logo3.svg" alt="" />
                <img src="/Images/logo1.svg" alt="" />
                <img src="/Images/logo2.svg" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="section4">
          <div className="section4BlockContainer">
            <div className="section4Block1">
              <div className="section4Block1Heading">
                <div className="tag">
                  <div>services</div>
                </div>
                <h2>
                  How <span>We Support</span> You
                </h2>
              </div>
              <div className="section4Block1Title">
                Turning Ideas into Market-Ready Solutions
              </div>
            </div>

            <div className="section4Block2">
              <div className="section4Block2Container">
                <img src="/Images/icon2.png" alt="" />
                <div className="section4Block2ContainerDescription">
                  <h3>Web Development</h3>
                  <div className="subTitle">
                    Nam pretium turpis et arcu. Etiam ultricies nisi vel augue.
                    Suspendisse pulvinar, augue ac venenatis.
                  </div>
                </div>
              </div>

              <div className="section4Block2Container">
                <img src="/Images/icon3.png" alt="" />
                <div className="section4Block2ContainerDescription">
                  <h3>Web Deployment</h3>
                  <div className="subTitle">
                    Nam pretium turpis et arcu. Etiam ultricies nisi vel augue.
                    Suspendisse pulvinar, augue ac venenatis.
                  </div>
                </div>
              </div>

              <div className="section4Block2Container">
                <img src="/Images/icon4.png" alt="" />
                <div className="section4Block2ContainerDescription">
                  <h3>Web Design</h3>
                  <div className="subTitle">
                    Nam pretium turpis et arcu. Etiam ultricies nisi vel augue.
                    Suspendisse pulvinar, augue ac venenatis.
                  </div>
                </div>
              </div>
            </div>

            <div className="section4Block3">
              <a
                href="mailto:ABSEnterprises2236@gmail.com"
                style={{ textDecoration: 'unset' }}
              >
                <button>
                  <div>let's talk</div>
                  <div
                    className="circle"
                    style={{ backgroundColor: '#126fff' }}
                  >
                    <img src="/Images/rightarraowlight.svg" alt="" />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </section>

        <section>
          <div className="videoSection">
            <video autoPlay muted loop>
              <source src="/Images/cover.mp4" />
              {/* <!-- <source
                        src="https://assets-global.website-files.com/661f2017073d0fce649e522a/661f2017073d0fce649e52fd_showreel-transcode.mp4" /> --> */}
            </video>

            <div className="section5Layout">
              <div className="videoContent">
                <div>
                  <h1>Embrace the present,</h1>
                  <h1>but strive for a better tomorrow.</h1>
                </div>

                <button className="videoBtn">
                  <img src="/Images//videoPlay.svg" alt="" />
                  <div>Watch showreel</div>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="section6Slider">
          <div className="section6MainContainer">
            <div>
              <div className="swiper mySwiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div>
                      <img
                        src="/Images/5star.svg"
                        alt=""
                        className="section6SliderImg"
                      />
                      <h2 className="sliderHeading">
                        Working with ABS Enterprises has been an outstanding
                        experience that greatly
                        <span> benefited our business</span>. Their expertise is
                        exceptional, earning our highest recommendation—10/10!
                      </h2>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div>
                      <img
                        src="/Images/5star.svg"
                        alt=""
                        className="section6SliderImg"
                      />
                      <h2 className="sliderHeading">
                        The team at ABS Enterprises excels in creating visually
                        stunning,
                        <span>user-friendly web designs</span>
                        tailored to our needs. Their innovative approach ensures
                        excellent user experiences.
                      </h2>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div>
                      <img
                        src="/Images/5star.svg"
                        alt=""
                        className="section6SliderImg"
                      />
                      <h2 className="sliderHeading">
                        They use the latest technologies to build robust,
                        scalable, and
                        <span>high-performing websites</span>. Their commitment
                        to quality results in efficient and seamless websites
                        that meet all our business requirements.
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
              </div>
            </div>
          </div>
        </section>

        <section className="section7Container">
          <div className="section7BlockContainer">
            <div className="section7Cards">
              <div className="section7singleCard">
                <img src="/Images/icon5.svg" alt="" />
                <div className="section7Title">
                  <h3>Become global</h3>
                  <div>
                    Fusce neque. Fusce risus nisl, viverra et, tempor et,
                    pretium in, sapien. Pellentesque posuere.
                  </div>
                </div>
                <div className="section7readMore">read more</div>
              </div>

              <div className="section7singleCard">
                <img src="/Images/icon6.svg" alt="" />
                <div className="section7Title">
                  <h3>Improve data</h3>
                  <div>
                    Fusce neque. Fusce risus nisl, viverra et, tempor et,
                    pretium in, sapien. Pellentesque posuere.
                  </div>
                </div>
                <div className="section7readMore">read more</div>
              </div>

              <div className="section7singleCard">
                <img src="/Images/icon7.svg" alt="" />
                <div className="section7Title">
                  <h3>The missing puzzle</h3>
                  <div>
                    Fusce neque. Fusce risus nisl, viverra et, tempor et,
                    pretium in, sapien. Pellentesque posuere.
                  </div>
                </div>
                <div className="section7readMore">read more</div>
              </div>
            </div>
          </div>
        </section>

        <section className="section8Stack">
          <div className="section8BlockContainer">
            <div className="section8BlockContainerHeading">
              <div className="section8Heading">
                <div>our technology</div>
              </div>
              <h2 className="techStack">Technology we love</h2>
            </div>
          </div>
          <div className="section8CircleContainer">
            <div className="section8MiddleCircleContainer">
              <div className="section8InnerCircleContainer">
                <div className="section8Circle">
                  <img src="/Images/angular.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/react.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/html.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/css.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/js.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/node.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/express.jfif" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/nextjs.jfif" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/php.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/mysql.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/mongo.png" alt="" />
                </div>
                <div className="section8Circle">
                  <img src="/Images/postgres.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="section8LowerContainer">
            <div className="section8WrapText">
              <div className="section8Title">
                We offer software development services at competitive rates in
                exchange for equity. Our success is tied to yours, giving us a
                vested interest in making OUR product a triumph.
              </div>

              <div className="section8Title">
                We adopt a collaborative approach to project management,
                partnering with our clients to thoroughly understand their
                requirements and establish achievable timelines and milestones.
              </div>

              <a
                href="mailto:ABSEnterprises2236@gmail.com"
                style={{ textDecoration: 'unset' }}
              >
                <button className="section8btnContainer">
                  <div>let's talk</div>
                  <div className="section8btnContainerCircle">
                    <img src="/Images/rightarraowlight.svg" alt="" />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </section>

        <section className="section9GallerySection">
          <div className="section9Container">
            <div className="gridGalleryHalf">
              <img
                className="img1"
                src="/Images/aboutBigThumb.jpg"
                alt=""
                style={{ width: '480px' }}
              />
              <img
                className="img2"
                src="/Images/cta-bg.jpg"
                alt=""
                style={{ minWidth: '580px' }}
              />
            </div>
          </div>
        </section>

        <section className="section10container">
          <div className="section10MainContainer">
            <div className="section10WrapContainer">
              <img src="/Images/quotes.svg" alt="" />
              <div className="section10Text">
                Continuously learning and innovating to create a better world
                for future generations.
              </div>
            </div>
          </div>
        </section>

        <section className="section11Container">
          <div className="section11blockContainer">
            <div className="section11blockHeading">
              <div className="section11CenterTagHeading">
                <div className="section11accent">
                  <div>our values</div>
                </div>
                <h2>Innovative software built on strong values.</h2>
              </div>
            </div>
            <div className="section11Tabs">
              <div className="section11Tab1">
                <div
                  className={`section11TabHeading ${steps === 1 && 'active'}`}
                  onClick={(event) => setValue(event, 1)}
                >
                  Learn
                </div>
                <div
                  className={`section11TabHeading ${steps === 2 && 'active'}`}
                  onClick={(event) => setValue(event, 2)}
                >
                  Create
                </div>
                <div
                  className={`section11TabHeading ${steps === 3 && 'active'}`}
                  onClick={(event) => setValue(event, 3)}
                >
                  Grow
                </div>
              </div>
              <div className="section11TabContent">
                <div>
                  {steps === 1 && (
                    <div
                      data-aos="fade-left"
                      className="section11div1 section11TabDescription1"
                    >
                      <div>
                        At the heart of our services lies the philosophy of
                        Learn, Grow, and Create. We believe in continuous
                        learning to stay ahead of the curve, ensuring we bring
                        the latest and most effective solutions to our clients.
                        <br />
                        <br />
                        Partner with us to learn, grow, and create together,
                        turning your vision into a powerful online presence.
                      </div>
                      <img src="/Images/aboutBigThumb.jpg" alt="" />
                    </div>
                  )}

                  {steps === 2 && (
                    <div
                      data-aos="fade-left"
                      className="section11div2 section11TabDescription1"
                    >
                      <div>
                        As we grow alongside you, our expertise expands,
                        enabling us to tackle challenges with innovative
                        approaches. Our commitment to creating goes beyond just
                        building websites; we craft tailored digital experiences
                        that resonate with your audience and drive results.
                        <br />
                        <br />
                        Partner with us to learn, grow, and create together,
                        turning your vision into a powerful online presence.
                      </div>
                      <img src="/Images/create.jpg" alt="" />
                    </div>
                  )}

                  {steps === 3 && (
                    <div
                      data-aos="fade-left"
                      className="section11div3 section11TabDescription1"
                    >
                      <div>
                        As we grow alongside you, our expertise expands,
                        enabling us to tackle challenges with innovative
                        approaches. Our commitment to creating goes beyond just
                        building websites; we craft tailored digital experiences
                        that resonate with your audience and drive results.
                      </div>
                      <img src="/Images/grow.jpg" alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <img
            className="section11ContainerBgImage"
            src="/Images/whiteBackgroundBubble.svg"
            alt=""
          />
        </section>

        <section className="section12Container">
          <div className="section12MainContainer">
            <div className="section12UpperHeading">
              <div className="section12Tag">
                <div>achievements</div>
              </div>
              <h2>
                Recognized for <span>Excellence</span>
              </h2>
            </div>
            <div className="section12LowerContainer">
              <div className="section12LowerLeftContainer">
                <div className="lowerleftParagraph">
                  Our journey has been marked by a steadfast commitment to
                  quality and innovation, earning us recognition and awards in
                  the industry. At [Your Company Name], we don’t just meet
                  standards; we set them. Our accolades are a testament to our
                  dedication, expertise, and the trust our clients place in us.{' '}
                  <br />
                  <br />
                  Every project we undertake is driven by a passion for
                  excellence. From the initial consultation to the final
                  deployment, we ensure that our solutions are not only
                  cutting-edge but also perfectly aligned with your business
                  objectives. Our team of experts stays abreast of the latest
                  trends and technologies, continually learning and growing to
                  deliver unparalleled results.
                  <br />
                  <br />
                  But it’s not just about the awards; it’s about what they
                  represent. They signify our relentless pursuit of perfection
                  and our unwavering commitment to client satisfaction. Each
                  recognition we receive reflects the hard work, creativity, and
                  dedication we pour into every project.
                </div>
              </div>
              <div className="section12LowerRightContainer">
                <div className="section12numberWrap">
                  <div className="section12numberText">2+</div>
                  <div className="section12numberHeading">
                    of 10+ companies work with us
                  </div>
                </div>
                <div className="section12divider"></div>
                <div className="section12numberWrap">
                  <div className="section12numberText">15+</div>
                  <div className="section12numberHeading">years on market</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section13" id="contact">
          <div className="section13Container">
            <div className="section13gridContainer">
              <img
                className="section13gridContainerImage"
                src="/Images/featureimg.jpg"
                alt=""
              />
              <div className="section13rightContainer">
                <div className="section13rightInnerContainer">
                  <img src="/Images/icon5.svg" alt="" />
                  <div className="section13TopContact">
                    <h2 className="section13Heading">Explore Our Expertise</h2>
                    <div>
                      Book a Consultation to See How Our Expertise Aligns with
                      Your Goals. Let’s Explore Your Needs and Collaborate for
                      Success.
                    </div>
                  </div>
                  <div className="section13divider"></div>
                  <div className="section13bottomContact">
                    <div className="section13bottomIconContainers">
                      <img src="/Images/message.svg" alt="" />
                      <a
                        href="mailto:ABSEnterprises2236@gmail.com"
                        style={{ textDecoration: 'unset', color: 'white' }}
                      >
                        <div>ABSEnterprises2236@gmail.com</div>
                      </a>
                    </div>
                    <div className="section13bottomIconContainers">
                      <img src="/Images/call.svg" alt="" />
                      <div>+91 7619-86-1358</div>
                    </div>
                    <div className="section13bottomIconContainers">
                      <img src="/Images/location.svg" alt="" />
                      <div>India, Kanpur</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer">
          <div className="footerMainContainer">
            <div className="section14UpperFooter">
              <div className="section13UpperLeftFooter">
                <div className="section13LogoimgContainer leftNavbar">
                  <p>
                    ABS <span>Enterprises</span>
                  </p>
                </div>
                <div className="section13FooterContactDetails">
                  <div className="footerSingleContactList">
                    <img src="/Images/message.svg" alt="" />
                    <a
                      href="mailto:ABSEnterprises2236@gmail.com"
                      style={{ textDecoration: 'unset', color: 'white' }}
                    >
                      <div>ABSEnterprises2236@gmail.com</div>
                    </a>
                  </div>
                  <div className="footerSingleContactList">
                    <img src="/Images/call.svg" alt="" />
                    <div>+91 7619-86-1358</div>
                  </div>
                  <div className="footerSingleContactList">
                    <img src="/Images/location.svg" alt="" />
                    <div>India</div>
                  </div>
                </div>
              </div>
              <div className="section13UpperRightFooter">
                <div className="section13columnFooterLink">
                  <div className="section13heading">Homepages</div>
                  <div className="section13FooterLinks">
                    <div>About</div>
                    <div>Contact</div>
                    <div>Career</div>
                  </div>
                </div>

                <div className="section13columnFooterLink">
                  <div className="section13heading">Homepages</div>
                  <div className="section13FooterLinks">
                    <div>About</div>
                    <div>Contact</div>
                    <div>Career</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section13FooterDivider"></div>
          </div>
        </section>
      </div>
    </>
  )
}

export default HomeComponent
